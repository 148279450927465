<template>
    <b-card>
        <Form
            ref="createGenre"
            :genre="genre"
            :submit="submit"
        />
    </b-card>
</template>

<script>
import Form from "./partials/Form";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "EditGenre",

    components: {
        Form
    },

    data() {
        return {
            id: this.$route.params.id
        }
    },

    computed: {
        ...mapGetters('genre', [
            'genreById'
        ]),
        genre() {
            let genre = this.genreById(this.id)
            if (!genre) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.$router.push({ name: 'genres' })

                return
            }
            genre.meta_keywords = genre.meta_keywords ? genre.meta_keywords.split(',') : []

            return genre
        }
    },

    methods: {
        ...mapActions({
            editGenre: 'genre/edit',
        }),
        submit() {
            const refGenre = this.$refs.createGenre
            refGenre.errMessage = null

            const data = JSON.parse(JSON.stringify(refGenre.genre))
            data.meta_keywords = data.meta_keywords.toString()
            this.editGenre(data).then(() => {
                this.$router.push({ name: 'genres' })
            }).catch(response => {
                if (response.errors) {
                    refGenre.$refs.form.setErrors(response.errors)
                }
                refGenre.errMessage = response.message
            })

        }
    }

}
</script>

<style scoped>

</style>